@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif";
    margin:auto;
}
.other-than-footer {
    min-height: calc(100vh - 104px);
}